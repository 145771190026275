function getMeemResult(form) {
  let somaPontos = 0;
  let somaMaxPontos = 0;
  form
    .querySelectorAll('input.resposta')
    .forEach((inputResposta) => {
      let pontos;
      switch (inputResposta.dataset.tipoResposta) {
        case 'integer':
          pontos = parseInt(inputResposta.value, 10);
          somaMaxPontos += parseInt(inputResposta.dataset.maxPontos, 10);
          if (Number.isNaN(pontos)) {
            pontos = 0;
          }
          break;
        case 'boolean':
          pontos = inputResposta.checked ? 1 : 0;
          somaMaxPontos++;
          break;
        default:
      }

      somaPontos += pontos;
    });
  return `${somaPontos} de ${somaMaxPontos}`;
}

document.addEventListener('turbolinks:load', () => {
  document
    .querySelectorAll('form.avaliacao_meem')
    .forEach((form) => {
      const resultInput = form.querySelector('.resultado');
      form
        .querySelectorAll('input.resposta')
        .forEach((inputResposta) => inputResposta.addEventListener('change', () => {
          // eslint-disable-next-line no-param-reassign
          resultInput.value = getMeemResult(form);
        }));
    });
});
