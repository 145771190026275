import $ from 'jquery';

function getSignatureHtmlFromData(textArea) {
  // let signature = $(textArea).data().signature
  let { signature } = textArea.dataset;
  if (signature) {
    signature = signature.replace(/\n/g, '<br/>');
    return `<span style="font-family: Arial"><br/><br/>${signature}</span>`;
  }

  return null;
}

function moveCursorToStartOfCurrentEditor() {
  window.getSelection().removeAllRanges();
}

document.addEventListener('turbolinks:load', () => {
  $.summernote.lang['pt-BR'].style.p = 'Normal';

  document.querySelectorAll('.richedit').forEach((textArea) => {
    const toolbar = [
      ['font', ['style', 'fontname', 'fontsize', 'color', 'bold', 'italic', 'underline', 'strikethrough', 'clear']],
      ['para', ['ol', 'ul', 'paragraph', 'height']],
      ['controls', ['fullscreen', 'undo', 'redo']],
      ['insert', ['hr']],
    ];

    if (textArea.dataset.advancedToolbar) {
      toolbar.push(['advanced', ['codeview', 'table']]);
    }

    $(textArea).summernote({ lang: 'pt-BR', toolbar });

    if (textArea.innerText.length === 0) {
      $(textArea).summernote('fontSize', 12);
      $(textArea).summernote('fontName', 'Arial');

      const signature = getSignatureHtmlFromData(textArea);
      if (signature) {
        $(textArea).summernote('pasteHTML', signature);
      }

      moveCursorToStartOfCurrentEditor();
    }
  });
});
