import modernizr from 'modernizr';
import { Datepicker } from 'vanillajs-datepicker';
import ptBR from 'vanillajs-datepicker/js/i18n/locales/pt-BR';
import InputMask from 'inputmask';
import Turbolinks from 'turbolinks';
import resizeTextArea from './textarea-resize';

Object.assign(Datepicker.locales, ptBR);

function formatDateStrToPtBr(dateStr) {
  const r = /(\d{4})-(\d{2})-(\d{2})/;

  if (r.test(dateStr)) {
    return dateStr.replace(r, '$3/$2/$1');
  }

  return dateStr;
}

function installDateBehavior() {
  if (!modernizr.inputtypes.date) {
    document
      .querySelectorAll('input[type=date]:not(.date-behavior-installed)')
      .forEach((input) => {
        input.value = formatDateStrToPtBr(input.value);
        new Datepicker(input, { format: 'dd/mm/yyyy', autohide: true, language: 'pt-BR' });
        new InputMask('99/99/9999').mask(input);
        input.classList.add('date-behavior-installed');
      });
  }
}

document.addEventListener('turbolinks:load', () => {
  installDateBehavior();

  document
    .querySelectorAll('textarea')
    .forEach((textarea) => {
      resizeTextArea(textarea, true);
    });

  document
    .querySelectorAll('tr[data-url] td')
    .forEach((td) => {
      td.addEventListener('click', () => {
        const { url } = td.parentElement.dataset;
        Turbolinks.visit(url);
      });
    });
});

document.addEventListener('keyup', (event) => {
  if (event.target.type === 'textarea') {
    resizeTextArea(event.target);
  }
});

export { formatDateStrToPtBr, installDateBehavior };
