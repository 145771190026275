import jQuery from 'jquery';

document.addEventListener('turbolinks:load', () => {
  Array.from(document.getElementsByClassName('modal-opener')).forEach((element) => {
    element.addEventListener('click', (event) => {
      event.preventDefault();

      const href = element.dataset.modalPath;
      fetch(href)
        .then((response) => response.text())
        .then((body) => {
          const modal = document.createElement('div');
          modal.innerHTML = body;
          document.body.prepend(modal);
          jQuery(modal.firstChild).modal().modal('show');
        });
    });
  });
});
