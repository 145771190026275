function getEdgResult(form) {
  let somaRespostasErradas = 0;
  let respondidas = 0;
  let total = 0;

  form
    .querySelectorAll('.pergunta')
    .forEach((divPergunta) => {
      const { respostaPadrao } = divPergunta.dataset;
      const inputResposta = divPergunta.querySelector('input.resposta:checked');
      if (inputResposta?.checked) {
        respondidas++;
        if (inputResposta.value !== respostaPadrao) {
          somaRespostasErradas++;
        }
      }
      total++;
    });

  if (respondidas < total) {
    return 'Existem perguntas não respondidas';
  }
  if (somaRespostasErradas > 5) {
    return `${somaRespostasErradas} respostas diferentes do padrão - suspeição de depressão`;
  }

  return `${somaRespostasErradas} respostas diferentes do padrão - normal`;
}

document.addEventListener('turbolinks:load', () => {
  document
    .querySelectorAll('form.avaliacao_edg')
    .forEach((form) => {
      const resultInput = form.querySelector('.resultado');
      form
        .querySelectorAll('input.resposta')
        .forEach((inputResposta) => inputResposta.addEventListener('change', () => {
          // eslint-disable-next-line no-param-reassign
          resultInput.value = getEdgResult(form);
        }));
    });
});
