import jQuery from 'jquery';

function inicializaSelects() {
  jQuery('select.select2')
    .not('.select2-installed')
    .select2()
    .addClass('select2-installed');
}

document.addEventListener('turbolinks:load', inicializaSelects);
