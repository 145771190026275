document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.role-resource-select-all').forEach((element) => {
    element.addEventListener('click', (event) => {
      const ckSelectAllResourceActions = event.target;

      ckSelectAllResourceActions
        .closest('.resource')
        .querySelectorAll('.role-actions input[type=checkbox]')
        .forEach((checkbox) => {
          checkbox.checked = ckSelectAllResourceActions.checked;
        });
    });
  });

  document.querySelectorAll('.role-select-all').forEach((element) => {
    element.addEventListener('click', (event) => {
      const ckSelectAll = event.target;
      document
        .querySelectorAll('input[type=checkbox]')
        .forEach((checkbox) => {
          checkbox.checked = ckSelectAll.checked;
        });
    });
  });
});
