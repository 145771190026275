// eslint-disable-next-line import/prefer-default-export
export function sumCheckedInputValues(form, selector) {
  let sum = 0;
  let total = 0;

  form.querySelectorAll(selector).forEach((input) => {
    if (input.checked) {
      sum += parseInt(input.value, 10);
    }
    total++;
  });

  return { sum, total };
}
