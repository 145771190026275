/* eslint-disable no-return-assign */
import Vue from 'vue/dist/vue.esm';
import { debounce } from 'lodash';

const mountSearchOn = (el) => (
  new Vue({
    el,

    data: () => (
      {
        inputQuery: '',
        query: '',
        onlyActive: true,
        residentes: [],
        loading: true,
        sortedBy: 'nome',
        sortedReverse: false,
      }
    ),

    mounted() {
      this.fetchResidentes();
    },

    computed: {
      filteredResidentes: (data) => {
        const removeAccents = (word) => (
          // https://stackoverflow.com/a/37511463/
          word.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        );

        const q = removeAccents(data.query.toLowerCase());

        return data.residentes.filter((residente) => {
          if (data.onlyActive && !residente.ativo) {
            return false;
          }

          return !q.length || removeAccents(residente.nome.toLowerCase()).indexOf(q) >= 0;
        }).sort((a, b) => {
          let va = a[data.sortedBy];
          if (va === null) va = '';

          let vb = b[data.sortedBy];
          if (vb === null) vb = '';

          if (va === vb) return 0;
          const result = va > vb ? 1 : -1;

          return data.sortedReverse ? -result : result;
        });
      },
    },

    filters: {
      // Need to append zero time to prevent it to use current TZ and show the day before
      formatDate: (value) => new Date(`${value}T00:00:00`).toLocaleDateString('pt-BR'),
    },

    methods: {
      href: (residenteId) => `/residentes/${residenteId}`,

      visit(residente) {
        // eslint-disable-next-line no-restricted-globals
        location.href = `/residentes/${residente.id}`;
      },

      fetchResidentes() {
        this.loading = true;
        fetch(`/residentes.json?apenas_ativos=${this.onlyActive}`)
          .then((r) => r.json())
          .then((data) => this.residentes = data)
          .then(() => this.loading = false);
      },

      debounceInput(evt) {
        this.inputQuery = evt.target.value; // This is needed for mobile browsers

        return debounce(() => { this.query = this.inputQuery }, 200)();
      },

      keyPressed(event) {
        if (event.keyCode === 13 && this.filteredResidentes.length === 1) {
          this.navigateToFirstResidente();
        }
      },

      navigateToFirstResidente() {
        window.location.href = this.href(this.filteredResidentes[0].id);
      },

      sortBy(field) {
        if (field === this.sortedBy) {
          this.sortedReverse = !this.sortedReverse;
        } else {
          this.sortedBy = field;
          this.sortedReverse = false;
        }
      },

      sortIndicator(field) {
        if (this.sortedBy !== field) {
          return '\xa0';
        }
        return this.sortedReverse ? '▼' : '▲';
      },
    },
  })
);

// eslint-disable-next-line no-restricted-globals
addEventListener('turbolinks:load', () => {
  const el = document.getElementById('residentes-search');

  if (el) {
    mountSearchOn(el);
  }
});
