import { sumCheckedInputValues } from './form-utils';

document.addEventListener('turbolinks:load', () => {
  document
    .querySelectorAll('form.avaliacao_avd')
    .forEach((form) => {
      form
        .querySelectorAll('input.resposta')
        .forEach((inputResposta) => inputResposta.addEventListener('change', () => {
          const result = sumCheckedInputValues(form, 'input.resposta');
          // eslint-disable-next-line no-param-reassign
          form.querySelector('.resultado').value = `${result.sum} / ${result.total}`;
        }));
    });
});
