require('@rails/activestorage').start();

function adjustRemovableElements() {
  Array
    .from(document.getElementsByClassName('remove-element'))
    .forEach((removeElement) => {
      removeElement.addEventListener('click', () => {
        removeElement
          .closest(`.${removeElement.dataset.removeElementOuterClass}`)
          .remove();
      });
    });
}

document.addEventListener('turbolinks:load', adjustRemovableElements);
