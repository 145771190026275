let resizeTextAreaTimeout;

export default function resizeTextArea(ta, immediate) {
  if (immediate) {
    const { cols, value } = ta;

    let linecount = 0;

    value.split('\n').forEach((line) => {
      linecount += Math.ceil(line.length / cols) || 1;
    });

    // eslint-disable-next-line no-param-reassign
    ta.rows = linecount + 2;
  } else {
    if (resizeTextAreaTimeout) {
      clearTimeout(resizeTextAreaTimeout);
    }
    resizeTextAreaTimeout = setTimeout(() => resizeTextArea(ta, true), 200);
  }
}
