/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// CSS
import './application.css.scss';
import '../css/impressao.css.scss';
// import 'images/favicon.ico'
// import 'images/thumb/icon.png'
// import 'images/thumb/thumb.png'
// import 'images/thumb/small.png'
// import 'images/thumb/medium.png'

// Javascripts
import 'bootstrap';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import jQuery from 'jquery';
import 'summernote';
import 'summernote/lang/summernote-pt-BR';
import 'select2';
import 'cocoon-js-vanilla';

import '../src/js/file_upload_edit_forms';
import '../src/js/direct_uploads';
import '../src/js/polyfills/node-list_for-each';

import '../src/js/residentes_search';
import '../src/js/avaliacoes_avd';
import '../src/js/avaliacoes_aivd';
import '../src/js/avaliacoes_edg';
import '../src/js/avaliacoes_meem';
import '../src/js/base';
import '../src/js/modals';
import '../src/js/rich_edits';
import '../src/js/roles';
import '../src/js/external/rollbar';
import '../src/js/selects';

require.context('../images', true, /\.(?:png|jpg|gif|ico|svg)$/);

Rails.start();
Turbolinks.start();

window.jQuery = jQuery;
window.$ = jQuery;
